import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StaticImage } from 'gatsby-plugin-image';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';
import InputField from '~/components/atoms/InputField';
import SelectField from '~/components/atoms/SelectField';
import CheckboxField from '~/components/atoms/CheckboxField';
import Button from '~/components/atoms/Button';
import useFormSubmit from '~/hooks/useFormSubmit';

const validationSchema = Yup.object({
  host_name: Yup.string().required('Required'),
  host_email: Yup.string().email('Invalid email address').required('Required'),
  host_phone_number: Yup.string().required('Required'),
  pick_your_preferred_incentive: Yup.string().required('Required'),
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone: Yup.string().required('Required'),
  privacy: Yup.boolean().oneOf([true], 'This field must be checked'),
});

export default function ReferForm({ terms, isSubmitted, setIsSubmitted }) {
  const [errorMessage, setErrorMessage] = useState('');
  const { post, response } = useFormSubmit({
    portalId: '7026924',
    formId: 'b65a8028-d41c-4fc2-b096-c6515d8bef31',
    setIsSubmitted,
  });

  return (
    <section className={`mb-20 md:mb-36`}>
      <div className="container sm:px-10">
        <FadeInWhenVisible className="relative px-8 py-16 mb-32 md:py-24 md:pb-28 sm:px-10 bg-sand">
          <Formik
            initialValues={{
              host_name: '',
              host_email: '',
              host_phone_number: '',
              pick_your_preferred_incentive: '',
              firstname: '',
              lastname: '',
              email: '',
              phone: '',
              privacy: false,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setErrorMessage('');

              await post(values);

              if (response?.data?.status === 'error') {
                const errorHeading = response?.data?.message;
                const messages = response.data.errors.map(({message}) => message).join('<br/>')
                setErrorMessage(errorHeading+'<br /><br />'+messages);
              }
            }}
          >
            {(props) => (
              <>
                {!isSubmitted ? (
                  <Form id="referrer-form" className="grid max-w-2xl grid-cols-12 mx-auto gap-y-8 md:gap-12">
                    <h4 className="col-span-12 text-h4">About you</h4>
                    <InputField
                      divclassname="col-span-12 md:col-span-6"
                      name="host_name"
                      type="text"
                      label="Your full name"
                    />
                    <InputField
                      divclassname="col-span-12 md:col-span-6"
                      name="host_email"
                      type="email"
                      label="Your email address"
                    />
                    <InputField
                      divclassname="col-span-12 md:col-span-6"
                      name="host_phone_number"
                      type="text"
                      label="Your phone number"
                    />
                    <SelectField
                      divclassname="col-span-12 md:col-span-6"
                      name="pick_your_preferred_incentive"
                      label="Preferred incentive"
                      options={[
                        { label: 'Up to £400 off your next bill', value: 'Up to £400 off your next bill' },
                        { label: '2 nights free stay in London', value: '2 nights free stay in London' },
                      ]}
                    />
                    <h4 className="col-span-12 mt-4 text-h4">About the host</h4>
                    <InputField
                      divclassname="col-span-12 md:col-span-6"
                      name="firstname"
                      type="text"
                      label="Host's first name"
                      autocomplete="off"
                    />
                    <InputField
                      divclassname="col-span-12 md:col-span-6"
                      name="lastname"
                      type="text"
                      label="Host's last name"
                      autocomplete="off"
                    />
                    <InputField
                      divclassname="col-span-12 md:col-span-6"
                      name="email"
                      type="email"
                      label="Host's email address"
                      autocomplete="off"
                    />
                    <InputField
                      divclassname="col-span-12 md:col-span-6"
                      name="phone"
                      type="text"
                      label="Host's phone number"
                      autocomplete="off"
                    />
                    <CheckboxField
                      divclassname="col-span-12"
                      name="privacy"
                      label={`By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>`}
                    />
                    <div className="col-span-12">
                      <Button title="Send referral" className="w-full" type="button" />
                      {errorMessage.length > 0 && (
                        <div className="mt-8 text-error text-sm max-w-md" dangerouslySetInnerHTML={{ __html: errorMessage }}/>
                      )}
                    </div>
                  </Form>
                ) : (
                  <div className="max-w-lg mx-auto text-center">
                    <StaticImage
                      src={'../../assets/images/form-success.png'}
                      objectFit="contain"
                      objectPosition="center"
                      alt=""
                      className="w-40 mb-8"
                    />
                    <h2 className="mb-4 text-h3">Got it!</h2>
                    <p className="max-w-xs mx-auto">
                      We received your request and someone will follow up in the next 1-2 business days.
                    </p>
                  </div>
                )}
              </>
            )}
          </Formik>
        </FadeInWhenVisible>
        {terms && (
          <FadeInWhenVisible className="max-w-2xl mx-auto">
            <div className="prose" dangerouslySetInnerHTML={{ __html: terms }} />
          </FadeInWhenVisible>
        )}
      </div>
    </section>
  );
}
