import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from '~/components/Layout';
import HeroHeading from '~/components/organisms/HeroHeading';
import ReferForm from '~/components/organisms/ReferForm';
import SEO from 'gatsby-plugin-wpgraphql-seo';

export default function Refer({ data: { wpPage } }) {
  const page = wpPage.refer.refer
  const hero = page.hero

  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Layout>
      <SEO post={wpPage} />
      <HeroHeading {...hero}  className="!bg-transparent !mb-0 md:!mb-0" />
      <ReferForm terms={page.terms} isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query referPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      refer {
        refer {
          terms
          hero {
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText
              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`
